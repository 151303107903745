import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import Section from 'helpers/Section';
import InputButtonGroup from 'components/FormComponents/group/InputButtonGroup';

const Newsletter = ({store}) => {
  const {subscription, email, onChange, onSubmit, translations: {
    title, description, enterMail, buttonText, thanksSubscribing
  }} = store;

  return (
    <Section sectionClass="py-5 bg-snow">
      <div className="grid small-gutter align-items-center">
        <div className="grid-item-md mb-2 mb-md-0">
          <h2 className="mb-0">{title}</h2>
          <span className="text-small text-muted">{description}</span>
        </div>
        <div className="grid-item-md-6-10">
          <div className="form-group">
            <InputButtonGroup
              field={email}
              size="lg"
              input={{
                name: 'email',
                placeholder: enterMail,
                label: enterMail,
                onChange: onChange
              }}
              button={{
                label: buttonText,
                onClick: onSubmit
              }}
            />
            {
              subscription && <div className="text-success text-center">{thanksSubscribing}</div>
            }
          </div>
        </div>
      </div>
    </Section>
  );
};

Newsletter.propTypes = {
  store: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    errors: PropTypes.object,
    email: PropTypes.shape({
      value: PropTypes.string.isRequired,
      error: PropTypes.object.isRequired,
      isLoading: PropTypes.bool.isRequired
    }).isRequired,
    subscription: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    translations: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      enterMail: PropTypes.string.isRequired,
      buttonText: PropTypes.string,
      emailLabel: PropTypes.string.isRequired,
      thanksSubscribing: PropTypes.string.isRequired
    }).isRequired
  })
};

export default observer(Newsletter);
