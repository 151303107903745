import {action, extendObservable, decorate} from 'mobx';
import validate from 'validate.js';

const newsleterConstraints = ({requiredValue, invalidEmailAddress}) => ({
  email: {
    presence: {
      message: `^${requiredValue}`
    },
    email: {
      message: `^${invalidEmailAddress}`
    }
  }
});

class NewsletterStore {
  constructor({subscribe, translations}) {
    extendObservable(this, {
      email: {
        value: '',
        error: [],
        isLoading: false
      },
      subscription: false
    });
    this.subscribe = subscribe;
    this.translations = translations;
  }

  onChange = (field, value) => {
    field.value = value;
    field.error = [];
    this.subscription = false;
  };

  onSubmit = async () => {
    const {email, translations: {errorMessages}} = this;
    const errors = validate(
      {email: email.value},
      newsleterConstraints(errorMessages)
    );

    if (errors) {
      email.error = errors.email;
    } else {
      try {
        email.isLoading = true;
        const {body: {errors}} = await this.subscribe(email.value)
          .ok(({status}) => [200, 400].includes(status));
        if (errors.email) {
          email.error = [errors.email.invalidEmail];
        } else {
          this.subscription = true;
        }
      } catch {
        email.error = [errorMessages.errorTryAgain];
      } finally {
        email.isLoading = false;
      } 
    }
  };
}

decorate(NewsletterStore, {
  onChange: action,
  onSubmit: action
});

export default NewsletterStore;
