import React from 'react';
import PropTypes from 'prop-types';
import LazyImage from 'helpers/LazyImage';
import getAppBgUrl from 'assets/images/getApp/appstore-bg-fs.jpg';
import getAppDevice from 'assets/images/getApp/appstore-device-fs.png';
import getAppBtn from 'assets/images/getApp/appstore-btn.png';
import googlePlayBtn from 'assets/images/getApp/googleplay-btn.png';

const GetAppFS = ({translations, appstoreUrl, playstoreUrl}) =>
  <div
    className="bg-cover"
    style={{
      backgroundImage: `url(${getAppBgUrl})`,
      backgroundPositionY: '60%'
    }}>
    <div className="bg-overlay-mid py-4 pb-lg-0">
      <div className="container text-light">
        <div className="grid align-items-center">
          <div className="grid-item-auto d-none d-lg-block">
            <LazyImage src={getAppDevice} title='app store' alt='app store' />
          </div>
          <div className="grid-item">
            <h2 className="display-2 font-light">
              {translations.planYourTripFromMobile}
            </h2>
            <p className="text-lead">{translations.availableApp}</p>
            {
              appstoreUrl && <a className="pr-2 d-inline-block" href={appstoreUrl}
                target="_blank" rel="noopener noreferrer">
                <LazyImage src={getAppBtn} title='app store' alt='app store' />
              </a>
            }
            {
              playstoreUrl && <a className="d-inline-block" href={playstoreUrl}
                target="_blank" rel="noopener noreferrer">
                <LazyImage src={googlePlayBtn} title='google play' alt='google play' />
              </a>
            }
          </div>
        </div>
      </div>
    </div>
  </div>;

GetAppFS.propTypes = {
  translations: PropTypes.shape({
    planYourTripFromMobile: PropTypes.string.isRequired,
    availableApp: PropTypes.string.isRequired
  }),
  appstoreUrl: PropTypes.string.isRequired,
  playstoreUrl: PropTypes.string.isRequired
};

export default GetAppFS;
