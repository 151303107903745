import React from 'react';
import PropTypes from 'prop-types';
import GetAppTP from './GetAppTP';
import GetAppAT from './GetAppAT';
import GetAppFS from './GetAppFS';

const GetApp = ({store}) => {
  const {isAirtickets, isFerryscanner} = store;
  const GetAppType = isAirtickets ? GetAppAT : isFerryscanner ? GetAppFS : GetAppTP;

  return (
    <section>
      <GetAppType {...store} />
    </section>
  );
};

GetApp.propTypes = {
  store: PropTypes.shape({
    isAirtickets: PropTypes.bool.isRequired,
    isFerryscanner: PropTypes.bool.isRequired,
    translations: PropTypes.object.isRequired
  })
};

export default GetApp;
