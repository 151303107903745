import {getAppTranslations} from 'helpers/translations';
import {market, isAirtickets, isFerryscanner,
  appstoreUrl, playstoreUrl} from 'helpers/stableValues';

export default {
  isAirtickets,
  isFerryscanner,
  market,
  appstoreUrl,
  playstoreUrl,
  translations: getAppTranslations
};
