import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import LazyImage from 'helpers/LazyImage';
import GetAppImage from 'assets/images/getApp/appstore-device-at.png';
import GetAppAward from 'assets/images/getApp/award_evo_17_at.png';
import GetAppIcon from 'assets/images/getApp/app-icon-at.svg';
import GetAppBtn from 'assets/images/getApp/appstore-btn.png';
import GooglePlayBtn from 'assets/images/getApp/googleplay-btn.png';

const GetAppAT = ({market, appstoreUrl, playstoreUrl, translations}) =>
  <div className="bg-secondary text-light py-lg-7 py-5">
    <div className="container">
      <div className="grid">
        <div className="grid-item-auto d-none d-lg-block">
          <LazyImage src={GetAppImage} />
        </div>
        <div className="grid-item">
          <div className="grid mb-4">
            <div className="grid-item-auto d-xs-none">
              <LazyImage imgClass="app-icon" src={GetAppIcon} width="88" title='get app' alt='get app' />
            </div>
            <div className="grid-item">
              <h2 className="display-3 font-light">{translations.downloadApp}</h2>
            </div>
          </div>
          <div className="grid">
            <div className="grid-item">
              <p>{translations.availableApp}</p>
              <div className="mb-3 d-flex">
                {
                  appstoreUrl && <a href={appstoreUrl} target="_blank" rel="noopener noreferrer">
                    <LazyImage
                      wrapperClass="mr-2"
                      src={GetAppBtn}
                      width="160"
                      title='get app button'
                      alt='get app button'
                    />&nbsp;&nbsp;
                  </a>
                }
                {
                  playstoreUrl && <a href={playstoreUrl} target="_blank" rel="noopener noreferrer">
                    <LazyImage src={GooglePlayBtn} width="160" title='google play' alt='googe play'/>
                  </a>
                }
              </div>
              {
                market === 'gr' && <Fragment>
                  <p>
                    <b>Android App!</b><br/>
                    Βραβεύτηκε στα e-volution awards 2017 GOLD AWARD airtickets® Mobile Apps.
                  </p>
                  <p>
                    <b>iPhone App!</b><br/>
                    Βραβεύτηκε στα e-volution awards 2016 SILVER AWARD airtickets® App Redesign.
                  </p>
                </Fragment>
              }
            </div>
            {
              market === 'gr' && <div className="grid-item-auto d-xs-none">
                <LazyImage src={GetAppAward} width="128" title='get app award' alt='get app award'/>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>;

GetAppAT.propTypes = {
  translations: PropTypes.shape({
    downloadApp: PropTypes.string.isRequired,
    availableApp: PropTypes.string.isRequired
  }),
  market: PropTypes.string.isRequired,
  appstoreUrl: PropTypes.string.isRequired,
  playstoreUrl: PropTypes.string.isRequired
};

export default GetAppAT;
