import React from 'react';
import PropTypes from 'prop-types';
import Slider, {Range} from 'rc-slider';

const TPSlider = ({
  withIndicators,
  label,
  resetBtn,
  type,
  ...rest
}) => {
  const {displayReset, resetLabel, onReset} = resetBtn;

  return (
    <div>
      {
        withIndicators &&
          <div className="rc-slider-label">
            { Boolean(label) && <b>{label}</b> }
            { displayReset && <a onClick={onReset}>{resetLabel}</a> }
          </div>
      }
      {
        type === 'range'
          ? <Range {...rest} />
          : <Slider {...rest} />
      }
    </div>
  );
};

TPSlider.propTypes = {
  isRTL: PropTypes.bool,
  withIndicators: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  resetLabel: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  marks: PropTypes.object.isRequired,
  step: PropTypes.number,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  resetBtn: PropTypes.shape({
    displayReset: PropTypes.bool,
    resetLabel: PropTypes.string,
    onReset: PropTypes.func
  })
};

TPSlider.defaultProps = {
  isRTL: false,
  withIndicators: false,
  label: '',
  resetLabel: '',
  min: 0,
  step: 1,
  type: 'slider'
};

export default TPSlider;
