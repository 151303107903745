import NewsletterStore from 'components/newsletter/store';
import {subscribe} from 'providers/newsletter';
import {newsletterTranslations, personalDetailsTranslations} from 'helpers/translations';

const translations = {
  ...newsletterTranslations,
  errorMessages: personalDetailsTranslations
};

export default new NewsletterStore({
  subscribe,
  translations
});
