import React from 'react';
import PropTypes from 'prop-types';
import {PropTypes as MobxPropTypes} from 'mobx-react';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {FieldErrors, Label} from 'components/FormComponents/components';
import Button from 'helpers/Button';

const InputButtonGroup = ({className, field, size, input, button, floatingLabel}) => {
  const {value, error, isLoading, isValid} = field;
  const {name, placeholder, label: labelInput, onChange, onBlur, labelProps, errorsContainerProps} = input;
  const {label, status, onClick} = button;
  const hasError = Boolean(error.length);

  const wrapperClasses = classNames('d-flex align-items-center', className);
  const htmlClasses = classNames('w-1-1 mb-0', {
    'has-error': hasError,
    'floating-label': floatingLabel,
    'floating-label-show': floatingLabel && !!value.length
  });
  const inputClasses = classNames('form-control border-radius-lg-left rounded-right-0', {
    [`form-control-${size}`]: size
  });

  return (
    <>
      <div className={wrapperClasses}>
        <div className={htmlClasses}>
          <input
            name={name}
            className={inputClasses}
            type="text"
            value={value}
            disabled={isLoading}
            onChange={(e) => onChange(field, e.target.value, name)}
            onBlur={(e) => onBlur && onBlur(field, e.target.value, name)}
            placeholder={placeholder || labelInput}
          />
          <Label {...labelProps} htmlFor={name}>{labelInput}</Label>
        </div>
        <span className="input-group-btn">
          <Button
            onClick={() => onClick(field)}
            status={status || 'primary'}
            size={size}
            aria-label={label}
            isLoading={isLoading}
          >{label}</Button>
        </span>
        {isValid && <i className='ml-2 text-success icon icon-c-check'></i>}
      </div>
      {hasError && <FieldErrors {...errorsContainerProps} errors={error} />}
    </>
  );
};

InputButtonGroup.propTypes = {
  className: PropTypes.string,
  field: PropTypes.shape({
    value: PropTypes.string.isRequired,
    error: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.string).isRequired,
    isLoading: PropTypes.bool.isRequired,
    isValid: PropTypes.bool
  }).isRequired,
  size: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    labelProps: PropTypes.shape({
      id: PropTypes.string,
      className: PropTypes.string
    }),
    errorsContainerProps: PropTypes.shape({
      id: PropTypes.string,
      className: PropTypes.string
    })
  }).isRequired,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    status: PropTypes.string,
    onClick: PropTypes.func.isRequired
  }).isRequired,
  floatingLabel: PropTypes.bool.isRequired
};

InputButtonGroup.defaultProps = {
  floatingLabel: true
};

export default observer(InputButtonGroup);
