import React from 'react';
import PropTypes from 'prop-types';
import LazyImage from 'helpers/LazyImage';
import GetAppBgUrl from 'assets/images/getApp/appstore-bg.jpg';
import tp24Device from 'assets/images/getApp/appstore-device-tp24.png';
import GetAppBtn from 'assets/images/getApp/appstore-btn.png';
import GooglePlayBtn from 'assets/images/getApp/googleplay-btn.png';

const GetAppTP = ({appstoreUrl, playstoreUrl, translations}) =>
  <div
    className="bg-cover bg-dark py-4 pb-lg-0"
    style={{backgroundImage: `url(${GetAppBgUrl})`}}>
    <div className="container">
      <div className="grid align-items-center">
        <div className="grid-item-auto d-none d-lg-block">
          <LazyImage src={tp24Device} title='app store' alt='app store' />
        </div>
        <div className="grid-item">
          <h2 className="display-2 font-light">
            {translations.planYourTripFromMobile}
          </h2>
          <p className="text-lead">{translations.availableApp}</p>
          {
            appstoreUrl && <a className="pr-2 d-inline-block" href={appstoreUrl}
              target="_blank" rel="noopener noreferrer">
              <LazyImage src={GetAppBtn} title='app store' alt='app store' />
            </a>
          }
          {
            playstoreUrl && <a className="d-inline-block" href={playstoreUrl}
              target="_blank" rel="noopener noreferrer">
              <LazyImage src={GooglePlayBtn} title='google play' alt='google play' />
            </a>
          }
        </div>
      </div>
    </div>
  </div>;

GetAppTP.propTypes = {
  translations: PropTypes.shape({
    planYourTripFromMobile: PropTypes.string.isRequired,
    availableApp: PropTypes.string.isRequired
  }),
  appstoreUrl: PropTypes.string.isRequired,
  playstoreUrl: PropTypes.string.isRequired
};

export default GetAppTP;
