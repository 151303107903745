import request from 'superagent';
import {localeFull} from 'helpers/stableValues';
import {domain} from 'ferries/helpers/common';

export const subscribe = (email) =>
  request
    .get(`${domain}/api/v1/ferries/subscribe/email`)
    .set('Content-Type', 'application/json')
    .query([
      `email=${email}`,
      `locale=${localeFull}`
    ].join('&'));
