import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Section = ({sectionClass, containerClass, containerStyle, renderTitle, children}) => {
  const containerClasses = classNames(
    'container',
    containerClass
  );

  return (
    <section className={sectionClass}>
      <div className={containerClasses} style={containerStyle}>
        {renderTitle()}
        {children}
      </div>
    </section>
  );
};

Section.propTypes = {
  sectionClass: PropTypes.string,
  containerClass: PropTypes.string,
  containerStyle: PropTypes.object,
  renderTitle: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

Section.defaultProps = {
  renderTitle: () => {}
};

export default Section;
